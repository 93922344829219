<template>
  <div>
    <div
      class="gavc-field"
      v-bind:class="{ 'is-error': repreneurUserUsername && $v.repreneurUserUsername.$dirty ? $v.repreneurUserUsername.$error : null  }">
      <label class="gavc-label" for="registration-repreneur-repreneurUserUsername-group">
        Email :
        <span class="gavc-required"> * </span>
        <p class="gavc-legende"> Il s'agira de votre identifiant pour vous connecter</p>
      </label>
      <input
        class="gavc-input"
        id="registration-repreneur-repreneurUserUsername-group"
        maxlength="150"
        @input="$v.repreneurUserUsername.$touch()"
        type="email"
        @change="findUserByUsername"
        placeholder="jon@donym.fr"
        v-model="repreneurUserUsername"
      >
      <p v-if="isExistingUser" class="mt-1 gavc-legende gavc-text-rose">
        Un utilisateur avec cet email existe déjà, veuillez choisir un autre email.
      </p>
      <p v-if="repreneurUserUsername && isExistingUser === false" class="mt-1 gavc-legende text-success">
        Cet identifiant est disponible
      </p>
    </div>

    <div
      class="gavc-field"
      v-bind:class="{ 'is-error': repreneurUserPassword && $v.repreneurUserPassword.$dirty ? $v.repreneurUserPassword.$error : null  }">
      <label class="gavc-label" for="registration-repreneur-repreneurUserPassword-group">
        Mot de passe :
        <span class="gavc-required"> * </span>
      </label>
      <passwordInput
        id="registration-repreneur-repreneurUserPassword-group"
        maxLength="150"
        @value-changed="repreneurUserPassword = $event"
        @input="$v.repreneurUserPassword.$touch()"
        :defaultValue="repreneurUserPassword"
      />
      <p
          class="gavc-legende mt-2"
          v-if="!$v.repreneurUserPassword.password_validation"
        >
          Votre mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial.
        </p>
    </div>

    <div
      class="gavc-field"
      v-bind:class="{ 'is-error': $v.repreneurUserPasswordConfirmation.$dirty ? $v.repreneurUserPasswordConfirmation.$error : null  }">
      <label class="gavc-label" for="registration-step-1-repreneurUserPasswordConfirmation-group">
        Confirmez votre mot de passe :
        <span class="gavc-required"> * </span>
      </label>
      <passwordInput
        id="registration-step-1-repreneurUserPasswordConfirmation-group"
        maxLength="100"
        @value-changed="repreneurUserPasswordConfirmation = $event"
        @input="$v.repreneurUserPasswordConfirmation.$touch()"
        :defaultValue="repreneurUserPasswordConfirmation"
      />
    </div>

    <div class="gavc-field">
      <div class="gavc-radio-checkbox">
        <div class="gavc-checkbox">
          <input 
            v-model="repreneurUserHasAuthorizedContact" 
            type="checkbox" 
            id="registration-step-5-has_authorized_contact-group" 
            name="checkbox-has_authorized_contact"
          >
          <label class="gavc-label" for="registration-step-5-has_authorized_contact-group">
            En cochant cette case, j'autorise Garance à vos côtés à me contacter 
            pour me conseiller dans mon projet de reprise ou me porter assistance dans mon projet
          </label>
        </div>
      </div>
    </div>

    <div class="gavc-field">
      <div class="gavc-radio-checkbox">
        <div class="gavc-checkbox">
          <input v-model="repreneurUserHasAcceptedCGUCGV" type="checkbox" id="registration-step-5-has_accepted_cgu_cgv-group" name="checkbox-has_accepted_cgu_cgv">
          <label class="gavc-label" for="registration-step-5-has_accepted_cgu_cgv-group">
            En cochant cette case, je certifie avoir lu et accepté les
            <a
              target="_blank"
              href="https://www.garance-a-vos-cotes.fr/mentions-legales-conditions-utilisations/"
              data-description=""
            >
              Conditions d'utilisation
            </a>
            et la <a
              target="_blank"
              href="https://www.garance-a-vos-cotes.fr/politique-de-confidentialite/"
              data-description=""
            >
              Politique de confidentialité
            </a>.
            <i class="gavc-icon gavc-icon-check" ></i>
          </label>
        </div>
      </div>
    </div>


    <div v-if="hasIdentifyCompany">
      <hr>
      <p class="gavc-chapo">Informations sur l'entreprise que vous avez identifiée et le cédant</p>
      <p class="gavc-p">
        Ces informations sont nécessaire pour créer un accès au cédant de l'entreprise
      </p>

      <cedantInformation
        v-on:invalidInformationChanged="isCedantInvalidValue = $event"
        v-on:isExistingCedantCompanyChanged="isExistingCedantCompany = $event"
        v-on:isExistingCedantUsernameChanged="isExistingCedantUsername = $event"
      />
    </div>


    <div class='text-center'>
      <button
        class="mt-2 ml-2 gavc-btn"
        @click="registerRepreneur"
        :disabled="isInvalidInformation || isExistingUser || (hasIdentifyCompany && ((isCedantInvalidValue || isExistingCedantUsername) && !isExistingCedantCompany))">
          Sauvegarder mes informations et créer mon compte
      </button>
    </div>
  </div>
</template>


<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email, sameAs } from 'vuelidate/lib/validators'
import { createHelpers } from 'vuex-map-fields';

import { APIDisconnectedService } from '@/api/APIDisconnected'
import uxMixins from '@/mixins/uxMixins'
import { password_validation } from '@/validators/validators'

const cedantInformation = () => import('@/components/registration/repreneur/cedantRegistration/cedantInformation')
const passwordInput = () => import('@/components/inputs/passwordInput')

const { mapFields } = createHelpers({
  mutationType: 'updateFieldWithLocalStorage',
});

const apiDisconnectedService = new APIDisconnectedService()

export default {
  name: 'repreneurRegistrationSaveAccount',
  components: {
    cedantInformation,
    passwordInput
  },
  props: {
    hasIdentifyCompany: {
      type: Boolean,
      default: false
    }
  },
  mixins: [
    uxMixins,
    validationMixin
  ],
  data: function () {
    return {
      isExistingUser: null,

      isCedantInvalidValue: false,
      isExistingCedantCompany: false,
      isExistingCedantUsername: false,
    }
  },
  computed: {
    ...mapFields([
      'repreneur.user.repreneurUserHasAcceptedCGUCGV',
      'repreneur.user.repreneurUserHasAuthorizedContact',
      'repreneur.user.repreneurUserPassword',
      'repreneur.user.repreneurUserPasswordConfirmation',
      'repreneur.user.repreneurUserUsername',
    ]),
    repreneurRegistrationForm () {
      return this.$store.getters.getRepreneurRegistrationForm
    },
    isInvalidInformation () {
      if (this.$v.$invalid || !this.repreneurUserHasAcceptedCGUCGV) {
        return true
      }
      return false
    },
  },

  validations: {
    repreneurUserHasAcceptedCGUCGV: {
      required
    },
    repreneurUserPassword: {
      maxLength: maxLength(150),
      required,
      password_validation
    },
    repreneurUserPasswordConfirmation: {
      maxLength: maxLength(150),
      required,
      sameAsPassword: sameAs('repreneurUserPassword'),
    },
    repreneurUserUsername: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required,
      email
    }
  },
  mounted () {
    if (this.repreneurUserUsername) {
      this.findUserByUsername()
    }
  },
  methods: {
    findUserByUsername () {
      if (this.$v.repreneurUserUsername.$invalid) {
        return
      }
      apiDisconnectedService.findUserByUsername(this.repreneurUserUsername)
      .then((result) => {
        this.isExistingUser = result.data.isUser
      })
      .catch((error) => {
        throw new Error('repreneurRegistrationSaveAccount findUserByUsername API Error : ' + String(error))
      })
    },
    registerRepreneur () {
      this.isRegistrationInProcess = true
      this.$store.commit('loadFromAPI')
      apiDisconnectedService.registerRepreneur(this.repreneurRegistrationForm)
      .then(() => {
        this.$router.push('/inscription/validation')
      })
      .catch((error) => {
        if (error.response && error.response.data.user.username && error.response.data.user.username[0] === "Un utilisateur avec ce nom existe déjà.") {
          this.isExistingUser = true
        }
        else {
          this.$store.commit('openGlobalErrorModal')
          throw new Error('Register register API Error : ' + String(error))
        }
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
        this.isRegistrationInProcess = false
      })
    }
  }
}
</script>
